<template>
	<!-- 账单记录 -->
	<div class="platform-fund-bond-record">
		<div class="pfbr-top">
			<div class="pfbr-top-money">
				<div class="pfbr-top-money-li">
					<div>保证金总额（元）</div>
					<div class="pfbrt-price">
						<span>{{money.amount}}</span>
					</div>
				</div>
				<div class="pfbr-top-money-li">
					<div>已缴纳保证金商户（个）</div>
					<div class="pfbrt-price">
						<span>{{money.total}}</span>
					</div>
				</div>
			</div>
			
			<div class="pfbr-search" style="margin-left: 24px;margin-top: 48px;">
				<div class="mb12" style="display: flex;">
					<span class="f12">查询对象：</span>
					<com-shop-search-select @change="e => search.key = e"></com-shop-search-select>
				</div>
				<div class="mb12">
					<span class="f12">账单时间：</span>
					<a-range-picker v-model:value="search.time"/>
				</div>
				<div class="mb12">
					<span class="f12">订单状态：</span>
					<a-select v-model:value="search.trade_status" mode="multiple" style="width: 324px;">
						<a-select-option value="">请选择</a-select-option>
						<a-select-option :value="1">进行中</a-select-option>
						<a-select-option :value="5">已退款</a-select-option>
						<a-select-option :value="6">交易成功</a-select-option>
						<a-select-option :value="7">交易失败</a-select-option>
					</a-select>
				</div>
				<a-button type="primary" @click="getBondRecord(1,info.limit)" style="margin-left: 60px;">筛选</a-button>
			</div>
		</div>
		
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'店铺名称',dataIndex:'user_name'},
				{title:'描述',dataIndex:'description'},
				{title:'金额',dataIndex:'condition',slots:{customRender:'amount'}},
				{title:'状态',dataIndex:'trade_status_name'},
				{title:'创建时间',dataIndex:'create_time'},
			]" >
				<template #amount="{record}">
					<span v-if="record.type == 1" style="color:rgb(39, 181, 33);">+{{record.amount}}</span>
					<span v-if="record.type == 2" style="color: #E74C3C;">-{{record.amount}}</span>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBondRecord(info.page,e)}"
					@change="(e)=>{getBondRecord(e,info.limit)}"
				/>
			</div>
		</div>
		
	</div>
</template>

<script>
import comShopSearchSelect from '@/components/miniapp/com-shop-search-select.vue'
import shopModel from '@/api/addons/shop.js'
import { reactive, toRefs } from 'vue'
export default{
	components:{
		comShopSearchSelect
	},
	setup(){
		const _d = reactive({
			search:{
				time:[],
				trade_status:[],
				key:''
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0,
			},
			money:{},
		})
		shopModel.getBailMoney(res=>{
			_d.money = res
			getBondRecord(1,_d.info.limit)
		})

		function getBondRecord(page,limit){
			shopModel.getBailTrade(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		return{
			...toRefs(_d),
			getBondRecord
		}
	}
}
</script>

<style lang="scss">
	.pfbr-top{
		width: 100%;
		background: #fff;
		padding-bottom: 24px;
		
		&-money{
			width:90%;
			height: 60px;
			display: flex;
			padding: 24px;
			border-bottom: 1px solid #ebeef5;
			margin-bottom: 24px;
			
			&-li{
				width: 400px;
				height: 100%;
				
				>div:first-child{
					color: #999;
					font-size: 14px;
					margin-bottom: 8px;
				}
				.pfbrt-price{
					span{
						font-size: 28px;
					}
				}
			}
		}
	}
</style>
